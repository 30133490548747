<template lang="pug">
.reg-faq
    .wuwow-card
      .wuwow-card-head
        h4.wuwow-card-title {{ $t('layout.common_problem') }}
        //- 判斷該分類是否有 FAQ
      .wuwow-card-body(v-if="filterCategory(faqList,faqType.id).length",v-for='faqType in faqTypeList' :key='faqType.id')
        h5.h5-faq-category  {{ faqType.displayName }}
        .wuwow-accordion
          b-card(v-for='(qusetion, index) in filterCategory(faqList,faqType.id)' :key='index' no-body='')
            b-card-header(v-b-toggle="faqType.displayName + '-' + index" header-tag='header' role='tab' @click='setCurrentShow(faqType.displayName, index)')
              | Ｑ {{ qusetion.title }}
              font-awesome-icon(:class="[\
              currentShowType == faqType.displayName && index === currentShowIndex\
              ? 'd-none'\
              : 'd-block',\
              ]" icon='angle-down')
              font-awesome-icon(:class="[\
              currentShowType == faqType.displayName && index === currentShowIndex\
              ? 'd-block'\
              : 'd-none',\
              ]" icon='angle-up')
            b-collapse(:id="faqType.displayName + '-' + index" accordion='analysia-accordion' role='tabpanel')
              b-card-body
                div(v-html='qusetion.description')
      .txt-faq-ps
        | 若還有任何疑問，歡迎至WUWOW Jr線上客服的Line留言，或是撥打技術客服專線喔！:)
        br
        br
        | WUWOW Jr線上客服：Line ID =
        a(:href='companyLineUrl') {{ companyLineId }}
        br
        | 技術客服專線：
        a(:href="'tel:' + companyPhone") {{ companyPhone }}
        br
        br
</template>

<script>
import companyConstant from '@/constants/company';
import platformConstant from '@/constants/platform';
import lioshutanApi from '@/api';
import lodash from 'lodash';

export default {
  name: 'Faq',

  data() {
    return {
      companyPhone: companyConstant.INFO.CONTACT_PHONE,

      companyLineId: companyConstant.INFO.LINE_ID,

      companyLineUrl: companyConstant.INFO.LINE_URL,

      currentShowType: '',
      currentShowIndex: '',

      classroomRule: {},
      platform: {},
      faqTypeList: [],
      faqList: [],
    };
  },

  async created() {
    /* 不能改順序 */
    const platformCodename = 'wuwow_junior';
    await this.getPlatform(platformCodename);
    await this.getFaqTypeList();
    await this.getStudentClassroomRule();
    await this.fetchFaqList();
  },

  methods: {
    setCurrentShow(type, index) {
      if (this.currentShowType === type && this.currentShowIndex === index) {
        this.currentShowType = '';
        this.currentShowIndex = '';
      } else {
        this.currentShowType = type;
        this.currentShowIndex = index;
      }
    },

    // 取得平台基本資料
    async getPlatform(codename) {
      const response = await lioshutanApi.common.getPlatformData(codename);
      this.platform = response.data.data.platform;
    },

    // 取得對應學員合約新舊版本
    async getStudentClassroomRule() {
      // 合約新舊版本放在教室規則裡
      const result = await lioshutanApi.vip.getBaseProfile();
      this.classroomRule = result.data.customized_classroom_rule;
    },
    // 取得FAQ分類列表
    async getFaqTypeList(){
      // const order = 'order';
      // beforeClassQuestions = 'before_class_questions', // 課前問題
      // afterClassQuestions = 'after_class_questions', // 課後問題
      // otherQuestions = 'other_questions'; // 其他問題
      // const codenameEnum = [beforeClassQuestions, afterClassQuestions, otherQuestions];

      // query string 需預設平台、codename 搜尋欄位
      const params = {
        platformId: this.platform.id ? this.platform.id : platformConstant.WUWOW_JUNIOR,
        codenameStatus: 'all',
      };

      // codenameEnum.forEach((codename, index) => {
      //   params[`codenameEnum[${index}]`] = codename;
      // });

      const response = await lioshutanApi.faq.getFaqTypeList(params);
      this.faqTypeList = this.faqTypeRename(response.data.contents.data.categories);
    },

    async fetchFaqList() {
      // 根據學員合約切換常見問題版本
      const contractVersion = {
        old: 'v1',
        new: 'v2',
      };
      const params = {
        dataVersion: this.classroomRule.new_rule ? contractVersion.new : contractVersion.old,
      };

      this.faqTypeList.forEach((category, index) => {
        params[`categoryIds[${index}]`] = category.id;
      });

      const response = await lioshutanApi.faq.getFaqList(params);
      this.faqList = this.faqRename(response.data.contents.data.frequentlyAskedQuestions);
    },

    filterCategory(sourceData, categoryId) {
      return sourceData.filter(item => item.categoryId === categoryId);
    },

    // 改名
    faqTypeRename(dataArr) {
      lodash.forOwn(
        dataArr,
        (dataObj) => {
          const newlyName = {
            platformId: dataObj.platform_id,
            id: dataObj.id,

            displayName: dataObj.display_name,
            codename: dataObj.codename,

            isEnable: Boolean(dataObj.is_enable),
            sort: dataObj.sort,

            useLocation: dataObj.use_location,
            description: dataObj.description,

            createdAt: dataObj.created_at,
            updatedAt: dataObj.updated_at,
            deletedAt: dataObj.deleted_at,

            class: dataObj.class,
          };
          lodash.forOwn(dataObj, (value, key) => delete dataObj[key]);
          lodash.assign(dataObj, newlyName);
        });

      return dataArr;
    },

    faqRename(dataArr) {
      lodash.forOwn(
        dataArr,
        (dataObj) => {
          const newlyName = {
            categoryId: dataObj.category_id,
            id: dataObj.id,

            title: dataObj.title,

            isEnable: Boolean(dataObj.is_enable),
            sort: dataObj.sort,

            dataVersion: dataObj.data_version,
            description: dataObj.description,

            createdAt: dataObj.created_at,
            updatedAt: dataObj.updated_at,
            deletedAt: dataObj.deleted_at,

            class: dataObj.class,
          };
          lodash.forOwn(dataObj, (value, key) => delete dataObj[key]);
          lodash.assign(dataObj, newlyName);
        });

      return dataArr;
    },
  },
};
</script>

<style lang="scss" scope>
@import '@/styles/vips/faq';
</style>
